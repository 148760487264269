.center-main {
  align-items: center;
  background: #222;
  display: flex;
  height: 100vh;
  justify-content: center;
  margin: 0;
}

/* .icon {
  top: 50%;
  left: 50%;
  width: 16em;
  height: 16em;
  transform: translate(-50%,-50%);
} */

.wave-holder {
  position: absolute !important;
  top: 40px !important;
  display: block !important;
  height: 150px;
  left: 50%;
  transform: translateX(-50%);
}

/* Audio */

.audio {
  color: #f78d72;
}

.audio::after,
.audio i,
.audio i::before,
.audio i::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0.25em;
  height: 0.5em;
  transform: translate(-50%, -50%);
  background: currentColor;
  border-radius: 0.125em;
}



@keyframes audio1 {
  9% {
    height: 1.75em;
  }
  36% {
    height: 0.5em;
  }
  42% {
    height: 2.375em;
  }
  69% {
    height: 0.5em;
  }
  76% {
    height: 3.625em;
  }
  100% {
    height: 1em;
  }
}

@keyframes audio2 {
  9% {
    height: 5.5em;
  }
  36% {
    height: 0.5em;
  }
  42% {
    height: 5.5em;
  }
  69% {
    height: 0.5em;
  }
  76% {
    height: 6.75em;
  }
  100% {
    height: 1em;
  }
}

@keyframes audio3 {
  9% {
    height: 6.5em;
  }
  36% {
    height: 0.5em;
  }
  42% {
    height: 3.5em;
  }
  69% {
    height: 0.5em;
  }
  76% {
    height: 3.5em;
  }
  100% {
    height: 1em;
  }
}

@keyframes audio4 {
  9% {
    height: 1.875em;
  }
  36% {
    height: 0.5em;
  }
  42% {
    height: 3.75em;
  }
  69% {
    height: 0.5em;
  }
  76% {
    height: 5.625em;
  }
  100% {
    height: 1em;
  }
}

@keyframes audio5 {
  9% {
    height: 5em;
  }
  36% {
    height: 0.5em;
  }
  42% {
    height: 1.25em;
  }
  69% {
    height: 0.5em;
  }
  76% {
    height: 3.75em;
  }
  100% {
    height: 1em;
  }
}

@keyframes audio6 {
  9% {
    height: 6.125em;
  }
  36% {
    height: 0.5em;
  }
  42% {
    height: 6.875em;
  }
  69% {
    height: 0.5em;
  }
  76% {
    height: 2.5em;
  }
  100% {
    height: 1em;
  }
}

@keyframes audio7 {
  9% {
    height: 3.125em;
  }
  36% {
    height: 0.5em;
  }
  42% {
    height: 1.875em;
  }
  69% {
    height: 0.5em;
  }
  76% {
    height: 3.75em;
  }
  100% {
    height: 1em;
  }
}

.pulse-up {
  animation: pulse-up 1s infinite ease-in-out alternate;
}

@keyframes pulse-up {
  from {
    transform: scale(0.93);
  }

  to {
    transform: scale(1);
  }
}

.muzik {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.listner {
  width: 8px;
  height: 4px;
  border-radius: 10px;
  background-color: #fff;
  animation: musicloader 1s ease-in-out infinite;
}

@keyframes musicloader {
  0% {
      height: 4px;
  }
  50% {
      height: 90px;
  }
  100% {
      height: 4px;
  }
}

.listner:nth-child(1) {
  background-color: #d715d7;
  animation-delay: 1s;
}

.listner:nth-child(2) {
  background-color: #d715d7;
  animation-delay: 0.8s;
}

.listner:nth-child(3) {
  background-color: #d715d7;
  animation-delay: 0.6s;
}

.listner:nth-child(4) {
  background-color: #d715d7;
  animation-delay: 0.4s;
}

.listner:nth-child(5) {
  background-color: #d715d7;
  animation-delay: 0.2s;
}

.listner:nth-child(6) {
  background-color: #d715d7;
  animation-delay: 0.4s;
}

.listner:nth-child(7) {
  background-color: #d715d7;
  animation-delay: 0.4s;
}

.listner:nth-child(8) {
  background-color: #d715d7;
  animation-delay: 0.6s;
}

.listner:nth-child(9) {
  background-color: #d715d7;
  animation-delay: 0.8s;
}

.listner:nth-child(10) {
  background-color: #d715d7;
  animation-delay: 1s;
}


.subtitle-text {
  text-align: center;
  color: white !important;
  max-width: 200px;
  margin-bottom: 20px;
}

.proceed-btn {
  padding: 10px 50px !important;
  background-color: #f78d72 !important;
  box-shadow: none !important;
  color: #222 !important;
  border-radius: 40px !important;
  text-transform: none !important;
  margin-top: 20px !important;
}

.start-up-dialog {
  padding: 50px !important;
}

.speaking-text {
  text-align: center;
  color: rgba(240, 248, 255, 0.242);
  font-size: 20px;
  position: absolute;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
}

.box {
  position: absolute;
  top: 50%;
  left: 50%;
}

.object {
  display: flex;
  flex: 0 1 100%;
  justify-content: center;
  align-items: center;
  align-content: stretch;
}

.outline {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 8px solid #c3e9ff;
  animation: pulse 3s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  position: absolute;
}

.button {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #5e92d6;
  box-shadow: 0px 0px 80px #78cbf8;
  position: absolute;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
    border: 65px solid #723516;
  }
  50% {
    border: solid rgb(249, 188, 159);
    opacity: 0.8;
  }

  90% {
    transform: scale(3.2);
    opacity: 0.2;
    border: 3px solid #4a2b14;
  }
  100% {
    transform: scale(3.3);
    opacity: 0;
    border: 1px solid #ff7a7a;
  }
}

.pulse-stop {
  animation: none !important;
}

.paused-rec {
  filter: grayscale(1) !important;
  -webkit-filter: grayscale(1) !important;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
    border: 65px solid #2088a5;
  }
  50% {
    border: solid rgb(53, 90, 170);
    opacity: 0.8;
  }

  90% {
    transform: scale(3.2);
    opacity: 0.2;
    border: 3px solid #2687bb;
  }
  100% {
    transform: scale(3.3);
    opacity: 0;
    border: 1px solid #7a89ff;
  }
}

#delayed {
  animation-delay: 1.5s;
}

#circlein {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #3982c1;
  box-shadow: 0px -2px 15px #b7fbf8;
  position: absolute;
}

.mic-icon {
  height: 60px;
  position: absolute;
  margin: 21px;
}

.micro-holder {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
}

.micro-holder-new {
  width: 100%;
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
}
.micro-holder-new canvas {
  width: 50% !important;
}

.result-txt {
  font-size: 25px !important;
  color: #c16639;
}

.question-result {
  color: white;
}

.stop-record-btn {
  padding: 10px 30px !important;
  background-color: #f78d72 !important;
  box-shadow: none !important;
  color: #222 !important;
  border-radius: 50px !important;
  margin-top: 30px !important;
}

.result-holder {
  max-height: 60vh !important;
  overflow-y: scroll;
}

/* test page */

.rec-holder {
  width: 50%;
  margin-top: 200px !important;
}

.reco-text-area {
  border: none !important;
  background-color: #5a5a5a !important;
  color: white !important;
  font-size: 15px !important;
  padding: 20px;
  border-radius: 30px;
  width: 100% !important;
  height: 30vh !important;
}
.reco-text-area:focus {
  border: none !important;
  outline: none !important;
}
.reco-text-area::placeholder {
  font-size: 20px; /* Adjust the font size as per your requirement */
}

.rec-button-holder {
  margin-bottom: 20px !important;
}
.rec-button-holder button {
  color: white !important;
}

.rec-button-holder button svg {
  width: 20px !important;
  margin-right: 8px !important ;
}

.kyc-dialog {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.275);
  backdrop-filter: blur(2px);
  z-index: 6000;
  top: 0;
}

.welcome {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 500;
  color: rgb(27, 92, 203);
}

.name {
  margin-bottom: 0px;
  font-size: 30px;
  font-weight: 500;
}

.dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: auto;
  background: white !important;
  border-radius: 10px;
  padding: 20px;
}

@media only screen and (max-width: 600px) {
  .rec-holder {
    width: 90% !important;
  }

  .micro-holder-new canvas {
    width: 100% !important;
  }
}
