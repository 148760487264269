@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500&family=Manrope:wght@200;300;400;500&display=swap");

body {
  margin: 0;
  font-family: "Manrope", sans-serif;
}

code {
  font-family: "Manrope", sans-serif;
}

.text-center {
  text-align: center !important;
}

.content-center {
  display: flex !important;
  justify-content: center !important;
}

p {
  margin-bottom: 0px !important;
}

.suspense-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
