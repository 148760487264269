.access-main {
  margin-top: 60px !important;
}

.access-heading {
  font-size: 30px !important;
  text-align: center;
  font-weight: 500 !important;
  position: absolute !important;
  top: 50px !important;
}

.access-type-holder {
  justify-self: center;
  align-self: center;
}

.access-heading-2 {
  font-size: 25px;
  font-weight: 500 !important;
  margin-top: 80px !important;
}

.access-tag {
  font-size: 18px !important;
  font-weight: 500;
}

.access-btn {
  background-color: #00b852 !important;
  color: white !important;
  width: 20%;
  margin-top: 80px !important;
  padding: 15px 20px !important;
  position: absolute !important;
  bottom: 50px !important;
}

.action-btn {
  color: white !important;
  width: 20%;
  margin-top: 80px !important;
  padding: 15px 20px !important;
  position: absolute !important;
  bottom: 50px !important;
}

.access-success-ico {
  width: 80% !important;
}

.green-btn {
  background-color: #00b852 !important;
  color: white !important;
  width: 80%;
  margin-top: 40px !important;
  padding: 15px 20px !important;
}

.pan-heading {
  font-size: 20px !important;
  text-align: center;
  font-weight: 500 !important;
  position: absolute !important;
  top: 50px !important;
}

.pan-card-img {
  width: 200px !important;
}

.edu-texts {
  padding: 0 20px;
  font-size: 20px;
  font-weight: 500 !important;
  text-align: center;
}

.camera-holder video {
  background: linear-gradient(to right, black 4px, transparent 4px) 0 0,
    linear-gradient(to right, black 4px, transparent 4px) 0 100%,
    linear-gradient(to left, black 4px, transparent 4px) 100% 0,
    linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
    linear-gradient(to top, black 4px, transparent 4px) 0 100%,
    linear-gradient(to top, black 4px, transparent 4px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding: 20px !important;
}

/* questions */

.stepper-holder {
  position: absolute;
  top: 20px !important;
}

.question-holder {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.question-holder .question-text {
  margin-bottom: 20px !important;
  font-size: 15px !important;
  font-weight: 600;
  width: 100%;
  text-align: center;
  padding: 0 40px;
}

.timer-camera {
  position: absolute;
  bottom: 15% !important;
}

.timer-holder {
  width: 20% !important;
}

.camera-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
  background-color: rgb(21, 21, 21);
}

.switch-camera-content {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottom-button-holder {
  width: 30%;
  margin-top: 80px !important;
  padding: 15px 20px !important;
  position: absolute !important;
  bottom: 50px !important;
}

.bottom-btn {
  background-color: #00b852 !important;
  color: white !important;
  width: 100%;
  padding: 15px 20px !important;
}

.bottom-btn-red {
  background-color: #ff0000 !important;
  margin-top: 10px !important;
}

.edu-content-holder {
  margin-top: 100px;
}

.edu-green-btn {
  width: 30% !important;
}

.enable-audio-btn {
  background-color: rgb(13, 158, 69) !important;
  padding: 15px 30px !important;
  color: white !important;
}

.video-info-image{
  width: 50%;
  max-height: 200px !important ;
}

@media only screen and (max-width: 600px) {
  .access-heading {
    font-size: 25px !important;
  }

  .access-btn {
    width: 80% !important;
  }
  .action-btn {
    width: 80% !important;
  }

  .access-success-ico {
    width: 150% !important;
  }

  .pan-card-img {
    width: 50% !important;
  }

  .stepper-holder {
    width: 100% !important;
  }

  .bottom-button-holder {
    width: 90%;
  }

  .timer-holder {
    width: 50% !important;
  }

  .edu-green-btn {
    width: 50% !important;
  }

  .video-info-image{
    width: 80% !important;
  }
}

.back-btn-holder {
  position: absolute;
  top: 10px !important;
  left: 10px !important;
}
